
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import ProjectIntegrationHeader from "@/views/projects/integration/header/ProjectIntegrationHeader.vue";
import {
  BaseEntityModel,
  FunnelModel,
  ProjectIntegrationModel,
  ProjectsFunnelsListModel,
  DateFilterModel,
  SetProjectsFunnelsListModelRequestModel,
  SalesFunnelManagerModel,
} from "@/services/types";
import { IconDefinition, faMinus } from "@fortawesome/free-solid-svg-icons";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import EmptyDataMessage from "@/components/emptyData/EmptyDataMessage.vue";
import * as utils from "@/services/utils";
import { i18n } from "@/i18n";

type FunnelListOptionsItem = BaseEntityModel & {
  type: number;
  disabled: boolean;
};

const ProjectIntegrationProps = Vue.extend({
  props: {
    projectId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
    integrationId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  components: {
    ProjectIntegrationHeader,
    ActionButton,
    FormInput,
    EmptyDataMessage,
  },
  watch: {
    "$store.getters.getProjectIntegrationFunnelsList": "setFunnelList",
  },
})
export default class ProjectIntegration extends ProjectIntegrationProps {
  componentClass = "ProjectIntegration";

  dividerIcon: IconDefinition = faMinus;
  selectedList: number[] = [];

  funnelList: FunnelListOptionsItem[] = [];

  callDates: DateFilterModel = {
    from: null,
    to: null,
  };

  minDuration = "00:00";
  maxDuration = "00:00";

  managerId = "";
  managerCheckboxStatus = false;
  closedDealsCheckboxStatus = true;
  anonymizePhoneNumbers = false;

  showAdditionalSettings = false;

  handleCheckedFunnelListChange(checked: number[]) {
    // get checked types
    const checkedItems = this.funnelList.filter((i) => checked.includes(i.id));
    let minCheckedTypeValue = Infinity;
    checkedItems.forEach((i) => {
      minCheckedTypeValue = Math.min(minCheckedTypeValue, i.type);
    });

    this.funnelList.forEach((item) => {
      if (minCheckedTypeValue === Infinity) {
        item.disabled = false;
        return;
      } else if (minCheckedTypeValue >= -1 && item.type <= -2) {
        item.disabled = true;
        return;
      } else if (minCheckedTypeValue <= -2 && item.type >= -1) {
        item.disabled = true;
        return;
      }
      item.disabled = false;
    });

    this.selectedList = checked;
  }

  // getFunnelListItemIsDisabled(id: number) {
  //
  // }

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT,
      this.projectId
    );
    this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_INTEGRATION_ID,
      this.integrationId
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST_GET
    );

    const list: ProjectsFunnelsListModel =
      this.$store.getters.getProjectIntegrationFunnelsList;

    if (!list) {
      return;
    }

    this.setInitialDates(list.items[0]);
    this.setInitialDurations(list.items[0]);

    this.fillLists(list);
  }

  async handleSelectFunnelBtnClick() {
    const request: SetProjectsFunnelsListModelRequestModel = {
      ids: this.selectedList,
      callDates: {
        from:
          this.callDates.from && this.callDates.from.length > 0
            ? this.callDates.from
            : null,
        to:
          this.callDates.to && this.callDates.to.length > 0
            ? this.callDates.to
            : null,
      },
      callDuration: {
        min:
          this.minDuration.length > 0 &&
          utils.getSecondsFromMmSsString(this.minDuration)
            ? utils.getSecondsFromMmSsString(this.minDuration)
            : null,
        max:
          this.maxDuration.length > 0 &&
          utils.getSecondsFromMmSsString(this.maxDuration)
            ? utils.getSecondsFromMmSsString(this.maxDuration)
            : null,
      },
      manager: this.getSalesFunnelManagerModel(),
      skipDealStateFilter: this.closedDealsCheckboxStatus ? 0 : 1,
      anonymizePhoneNumbers: this.anonymizePhoneNumbers ? 1 : 0,
    };
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECT_INTEGRATION_FUNNELS_LIST_SET,
      request
    );
    this.$router.push(`/calls`);
  }

  get projectName() {
    return this.$store.getters.getProjectIntegrationIntegration
      ? (
          this.$store.getters
            .getProjectIntegrationIntegration as ProjectIntegrationModel
        ).name
      : "Проект";
  }

  get integrationType() {
    return this.$store.getters.getProjectIntegrationType;
  }

  setFunnelList(list: ProjectsFunnelsListModel) {
    this.fillLists(list);
  }

  fillLists(list: ProjectsFunnelsListModel) {
    this.selectedList = [];
    this.funnelList = [];

    if (list && list.items.length > 0) {
      list.items.map((item) => {
        if (item.selected) {
          this.selectedList.push(item.id);
        }
        this.funnelList.push({
          id: item.id,
          name: item.name,
          type: item.type,
          disabled: false,
        });
      });
      if (
        list.items[0].manager &&
        list.items[0].manager.type &&
        list.items[0].manager.type == "LEAD"
      ) {
        this.managerCheckboxStatus = true;
        this.managerId = list.items[0].manager.id.toString();
      }
      if (list.items[0].skipDealStateFilter) {
        this.closedDealsCheckboxStatus = !list.items[0].skipDealStateFilter;
      }
    }

    this.handleCheckedFunnelListChange(this.selectedList);
  }

  minDurationListener(value: string) {
    this.minDuration = value;
  }

  maxDurationListener(value: string) {
    this.maxDuration = value;
  }

  managerIdListener(value: string) {
    this.managerId = value;
  }

  get loadData(): boolean {
    return !(
      (this.$store.getters
        .getProjectIntegrationFunnelsList as ProjectsFunnelsListModel) &&
      (
        this.$store.getters
          .getProjectIntegrationFunnelsList as ProjectsFunnelsListModel
      ).items.length >= 0
    );
  }

  setInitialDates(item: FunnelModel) {
    const date = new Date();
    date.setMonth(date.getUTCMonth() - 1);

    const from = utils.getFormattedDate(date);
    const to = utils.getFormattedDate(new Date());

    this.callDates.from =
      item && item.callDates && item.callDates.from
        ? item.callDates.from
        : from;
    this.callDates.to =
      item && item.callDates && item.callDates.to ? item.callDates.to : to;
  }

  setInitialDurations(item: FunnelModel) {
    this.minDuration =
      item && item.callDuration && item.callDuration.min
        ? utils.renderDurationWithDots(item.callDuration.min * 1000)
        : utils.renderDurationWithDots(30 * 1000);
    this.maxDuration =
      item && item.callDuration && item.callDuration.max
        ? utils.renderDurationWithDots(item.callDuration.max * 1000)
        : utils.renderDurationWithDots(300 * 1000);
  }

  get callsListIsLoading(): boolean {
    return this.$store.getters.isProjectIntegrationCallsListLoading;
  }

  handleRedirectCallPageBtnClick() {
    this.$router.push(`/calls`);
  }

  getSalesFunnelManagerModel(): SalesFunnelManagerModel {
    return {
      type: this.managerCheckboxStatus ? "LEAD" : "DEFAULT",
      id:
        this.managerCheckboxStatus && parseInt(this.managerId)
          ? parseInt(this.managerId)
          : null,
    };
  }

  get getAdditionalSettingsSwitcherTitle() {
    return this.showAdditionalSettings
      ? i18n.t("actions.hide")
      : i18n.t("projects.create.advancedSettingsTitle");
  }
}
